import Player from "./Player";
import React from "react";
import {PlayerSmallRender} from '../Page/PlayerSmallRender'

export default class PlayerSmall extends Player {
    render() {
        if (!this.state.stream) {
            return <div/>;
        }
        document.body.style.backgroundColor = this.state.backgroundColor;
        return <PlayerSmallRender player={this}/>
    }
}