import {Component} from "react";
import {
    formatConfig,
    getConfig,
    getConfigAsync,
    isAuthorization,
    defineSpecificUrl
} from "../Service/PlayerConfigService";
import AppConfig from "../config/config.json"
// https://github.com/hvianna/audioMotion-analyzer
import AudioMotionAnalyzer from "../vendor/audioMotion-analyzer";

export default class Player extends Component {
    volumeTimeout = null;
    titleToDisplay = null;
    authorToDisplay = null;
    imageToDisplay = null;
    isDefaultImage = null;
    audioMotion = null;
    hasError = false;
    isChangingImage = false;
    volumeTemp = 0;
    play = 0;
    playTemp = 0;
    queue = [];
    previewConfig = {};

    constructor(props) {
        super(props);
        let configUrl = AppConfig.urlConfig + props.radioName + '/config/' + props.configId;
        if (props.isTest) {
            configUrl = '/configTest.json';
        }
        this.state = {
            configUrl: configUrl,
            stream: null,
            locale: props.locale,
            autoplay: !!parseInt(props.autoplay),
            urlParam: props.url,
            preview: props.preview,
            controls: false,
            light: false,
            muted: false,
            playing: false,
            played: 0,
            loaded: 0,
            playbackRate: 1.0,
            neverPlayed: true,
            timeline: []
        }
    }

    componentDidMount = () => {
        let config = getConfig(this.state.configUrl);
        if (config === null) {
            //If no config found return to 404 pages
            window.location.href = '/404';
        }

        // Override config
        // Create the player with a fake url for not load the real one and do a hit in statistics
        config.url = 'temp.mp3';
        if (this.state.urlParam) {
            config = defineSpecificUrl(this.state.urlParam, config);
        }
        this.init(config);
        if (!this.hasError && !this.state.urlParam) {
            const player = this;
            // MODE PREVIEW
            if (this.state.preview) {
                this.addMessageListener(config);
                this.previewConfig = config;
            }
            setInterval(function () {
                if (!player.state.neverPlayed && player.state.playing && !player.hasError) {
                    async function asyncCall(urlParam) {
                        // On ne formate pas la config quand on la récupère, on attend de voir si il y a un changement avec la précédente
                        config = await getConfigAsync(urlParam);
                        if (config && (
                            (config.date > player.state.date) ||
                            (config.title !== player.state.title && player.state.stream.format !== 'hls') ||
                            (player.state.stream.format === 'hls' && config.timeline[config.timeline.length - 1] &&
                                player.state.timeline[player.state.timeline.length - 1].date !== config.timeline[config.timeline.length - 1].date)
                        )
                        ) {
                            // MODE PREVIEW
                            if (player.state.preview) {
                                player.previewConfig.title = config.title;
                                player.previewConfig.artist = config.artist;
                                player.previewConfig.cover = config.cover;
                                player.previewConfig.timeline = config.timeline;
                                config = player.previewConfig;
                            }
                            player.create(config);
                        }
                    }

                    // Call change config in async for avoid freeze
                    asyncCall(player.state.configUrl);
                }
            }, 5000);
        }
    }

    init = config => {
        let configFormated = formatConfig(config, this.state);

        // Define a volume temp for come back to the initial value
        this.volumeTemp = configFormated.volume;

        if (configFormated.stream.format === 'hls') {
            this.titleToDisplay = configFormated.timeline[0].title;
            this.authorToDisplay = configFormated.timeline[0].author;
            this.imageToDisplay = configFormated.timeline[0].cover;
        } else {
            this.titleToDisplay = configFormated.title;
            this.authorToDisplay = configFormated.author;
            this.imageToDisplay = configFormated.image;
        }

        // Default display playername without author
        configFormated.title = configFormated.playername;
        configFormated.author = "";
        this.setState(configFormated);
    }

    // Call in setInterval(5) function
    create = config => {
        let configFormated = formatConfig(config, this.state);

        // Error if the user is unauthorized, check only at the creation
        if (!isAuthorization(configFormated.stream)) {
            this.setPlayerInError(configFormated.unauthorizedUrl, configFormated);
        } else {
            if (configFormated.stream.format !== 'hls') {
                //Store in (title/author)ToDisplay for switch between hls and mp3/aac
                this.titleToDisplay = configFormated.title;
                this.authorToDisplay = configFormated.author;
                this.imageToDisplay = configFormated.image;
                this.changeImageAndTitleAuthor(this.imageToDisplay, this.titleToDisplay, this.authorToDisplay);
            }
            // keep previous title and author while changeImageAndTitleAuthor is not ready
            configFormated.title = this.state.title;
            configFormated.author = this.state.author;
            this.createAudioMotionSpectrum();
            this.setState(configFormated);
        }
    }

    // Call when user changes the quality
    // The config is not change here
    load = stream => {
        // Reset the error status when the player listen another stream
        this.hasError = false;

        // Apply a loading image for the transition between streams
        this.changeImageAndTitleAuthor(this.state.loadingUrl, this.state.playername, null, true);

        // Stop playing to remove stream loading
        this.handlePlayPause(null, true);

        // Set to the player the new stream url and define stream is played
        this.setState({url: stream.url, stream: stream}, function () {
            // Create spectrum in loading, not in ready state because is useless to create a spectrum on the same stream
            if (this.state.spectrum) {
                this.createAudioMotionSpectrum();
            }

            // Force to play when user loads a new stream
            if (!this.state.playing && !this.hasError && !this.state.neverPlayed) {
                this.handlePlayPause(null, false);
            }
        });

        // Reaply the init config after loading screen, only if the user has never click on play
        if (this.state.neverPlayed) {
            this.changeImageAndTitleAuthor(this.state.display, this.state.title, this.state.author);
        }
    }

    // Call at the creation or after load function
    // The config is not change here
    onPlayerReady = () => {
        if (this.state.stream.format === 'hls') {
            // Get the hls player, its undefined if the stream is not a HLS stream
            this.hlsPlayer = this.player.getInternalPlayer('hls');
            this.imageToDisplay = this.state.timeline[0].cover;
            this.titleToDisplay = this.state.timeline[0].title;
            this.authorToDisplay = this.state.timeline[0].author;
        }
        if (!this.hasError && !this.state.neverPlayed) {
            if (!isAuthorization(this.state.stream)) {
                this.setPlayerInError(this.state.unauthorizedUrl);
            } else {
                if (this.state.stream.format === 'hls') {
                    this.changeImageAndTitleAuthor(this.imageToDisplay, this.titleToDisplay, this.authorToDisplay);
                }
            }
        }

        if (this.state.neverPlayed && this.state.autoplay) {
            this.handlePlayPause(null, false);
        }
    }

    // Call every second when the player is playing and if the stream is up
    handleProgress = state => {
        if (this.play === 0) {
            // Reset the time position
            this.play = Date.now() / 1000 - this.state.playlist * this.state.fragment;
        }
        if (this.state.stream.format === 'hls' && state.playedSeconds > 0) {
            // In some cases the play/pause reset playedSeconds before playTemp
            if (state.playedSeconds >= this.playTemp) {
                this.play = this.play + state.playedSeconds - this.playTemp;
            }
            this.playTemp = state.playedSeconds;
            if (this.state.timeline.length > 1 && this.play >= this.state.timeline[1].date) {
                this.titleToDisplay = this.state.timeline[1].title;
                this.authorToDisplay = this.state.timeline[1].author;
                this.imageToDisplay = this.state.timeline[1].cover;
                this.changeImageAndTitleAuthor(this.state.timeline[1].cover, this.state.timeline[1].title, this.state.timeline[1].author);
                this.state.timeline.shift();
                this.setState({timeline: this.state.timeline});
            } else if (this.state.timeline[0].title !== this.state.title && !this.hasError && !this.state.neverPlayed) {
                this.titleToDisplay = this.state.timeline[0].title;
                this.authorToDisplay = this.state.timeline[0].author;
                this.imageToDisplay = this.state.timeline[0].cover;
                this.changeImageAndTitleAuthor(this.state.timeline[0].cover, this.state.timeline[0].title, this.state.timeline[0].author);
            }
        }
        this.setState(state);
    }

    handlePlayPause = (e, isPlaying = this.state.playing) => {
        // First play
        var firstPlay = false;
        if (this.state.neverPlayed) {
            this.setState({neverPlayed: false});
            document.getElementById("circle-play").remove();
            firstPlay = true;
        }

        if (!isPlaying) {
            // Start loading
            if (this.state.stream.format === 'hls') {
                // reload HLS stream at the end
                this.setState({url: this.state.stream.url}, function () {
                    this.onPlayerReady();
                    let imageName = this.state.display.split('/')[this.state.display.split('/').length - 1];
                    // Do animation for hls spectrum
                    if (this.state.spectrum && (imageName.includes('background') || imageName.includes('defaultImage'))) {
                        this.createAudioMotionSpectrum();
                        if (!firstPlay && e !== null) {
                            let spectrumHTML = document.getElementById('spectrum');
                            spectrumHTML.classList.remove("fadeOut");
                        }
                    }
                })
            } else {
                this.player.player.player.player.src = this.state.stream.url;
                this.player.player.player.player.load();
            }
            // Reset second played for reset the time position
            this.play = 0;
        } else {
            // Stop loading
            if (this.state.stream.format === 'hls' && this.hlsPlayer) {
                this.hlsPlayer.stopLoad();
                // change the stream url for reload the right stream at the play
                this.setState({url: ''});
            } else {
                // Empty the src player to stop loading
                this.player.player.player.player.src = '';
                this.player.player.player.player.load();
            }
        }
        this.setState({playing: !isPlaying});
    }

    handleToggleOverlay = overlayName => {
        const playerConfigClassList = document.getElementById(overlayName + "-overlay").classList;
        if (!playerConfigClassList.contains("show-overlay")) {
            playerConfigClassList.add("show-overlay");
        } else {
            playerConfigClassList.remove("show-overlay");
        }
    }

    handleVolumeChange = (e, value) => {
        this.setState({muted: value === 0});
        this.setState({volume: parseFloat(value)});
    }

    handleToggleMuted = () => {
        const isMuted = this.state.muted;
        if (!isMuted) {
            this.volumeTemp = this.state.volume;
            this.handleVolumeChange(null, 0);
        } else {
            this.handleVolumeChange(null, this.volumeTemp);
        }
        this.setState({muted: !isMuted});
    }

    handleVolumeHoverEnter = () => {
        document.getElementById("volume-container").classList.add("show-volume");
        clearTimeout(this.volumeTimeout);
    }

    handleVolumeHoverLeaveTimeOut = () => {
        this.volumeTimeout = setTimeout(() => {
            document.getElementById("volume-container").classList.remove("show-volume");
        }, 1000);
    }

    createAudioMotionSpectrum = () => {
        const options = {
            barSpace: 0.12, bgAlpha: 0, fftSize: 4096, fillAlpha: 0, lineWidth: 3, loRes: false, lumiBars: false,
            maxDecibels: -30, maxFreq: 8000, minDecibels: -90, minFreq: 500, mode: 3, overlay: true, radial: false,
            reflexAlpha: 0.15, reflexBright: 1, reflexFit: true, reflexRatio: 0, showBgColor: true, showFPS: false,
            showLeds: false, showPeaks: false, showScale: false, showScaleY: false, smoothing: 0.9, spinSpeed: 0
        };

        const gradiantOptions = {
            name: 'apple', bgColor: '#000', colorStops: [
                {pos: .1667, color: '#61bb46'}, {pos: .3333, color: '#fdb827'}, {pos: .5, color: '#f5821f'},
                {pos: .6667, color: '#e03a3e'}, {pos: .8333, color: '#963d97'}, {pos: 1, color: '#009ddc'}
            ], disabled: false
        }

        // Attach stream to spectrum
        function create(player) {
            player.audioMotion = new AudioMotionAnalyzer(document.getElementById('player-image'), options);
            player.audioMotion.registerGradient('apple', gradiantOptions);
            player.audioMotion.setOptions({gradient: 'apple'});
            player.audioMotion.connectAudio(player.player.getInternalPlayer());
        }

        if (this.audioMotion === null) {
            create(this);
        } else if (this.audioMotion.audioSource.mediaElement !== this.player.getInternalPlayer()) {
            // Destroy the previous spectrum for create new one will attach to new stream
            document.getElementById('spectrum').remove();
            create(this);
        }
    }

    // Animate all fadeOut
    changeImageAndTitleAuthor = (url, title, author, loading = false) => {
        if (this.state.stream.id !== -1) {
            if (!author) {
                author = '';
            }
            const imageHTML = document.getElementById('image');
            let spectrumHTML = document.getElementById('spectrum');
            const titleHTML = document.getElementById('title-container');
            const authorHTML = document.getElementById('author-container');
            var player = this;
            if (this.isChangingImage === false) {
                if (url !== player.state.display || title !== player.state.title || author !== player.state.author) {
                    this.isChangingImage = true;
                    if (spectrumHTML && (url !== player.state.display || !player.state.spectrum)) {
                        spectrumHTML.classList.add("fadeOut");
                    }

                    let activeSpectrum = false;
                    let imageName = url.split('/')[url.split('/').length - 1];
                    if (player.state.spectrum && (imageName.includes('background') || imageName.includes('defaultImage'))) {
                        activeSpectrum = true;
                    }

                    if (url !== player.state.display || activeSpectrum) {
                        imageHTML.classList.add("fadeOut");
                    }
                    titleHTML.classList.add("fadeOut");
                    authorHTML.classList.add("fadeOut");

                    if (!title) {
                        if (author) {
                            title = author;
                            author = "";
                        } else {
                            title = this.state.playername;
                        }
                    }
                    setTimeout(function () {
                        player.setState({display: url, title: title, author: author}, function () {
                            player.handleMarquee('title-container');
                            player.handleMarquee('author-container');
                            if (loading) {
                                imageHTML.classList.remove("fadeOut");
                            } else {
                                // Get spectrum html element because it can change
                                spectrumHTML = document.getElementById('spectrum');
                                if (!player.state.neverPlayed && activeSpectrum && spectrumHTML) {
                                    spectrumHTML.classList.remove("fadeOut");
                                } else {
                                    imageHTML.classList.remove("fadeOut");
                                }
                            }
                            titleHTML.classList.remove("fadeOut");
                            authorHTML.classList.remove("fadeOut");
                            setTimeout(function () {
                                player.isChangingImage = false;
                                player.updateMediaSession();
                                if (player.queue.length > 0) {
                                    let next = player.queue.shift();
                                    player.changeImageAndTitleAuthor(next.url, next.title, next.author, next.loading);
                                }
                            }, 1000);
                        });
                    }, 1100);
                } else if (player.queue.length > 0) {
                    let next = player.queue.shift();
                    player.changeImageAndTitleAuthor(next.url, next.title, next.author, next.loading);
                }
            } else {
                this.queue.push({url: url, title: title, author: author, loading: loading});
            }
        }
    }

    // handleMarquee is call in changeImageAndTitleAuthor
    handleMarquee = idElement => {
        const element = document.getElementById(idElement);
        if (element.offsetWidth < element.scrollWidth) {
            element.classList.add("marquee");
        } else {
            element.classList.remove("marquee");
        }
    }

    setPlayerInError = (imageToDisplay, config = this.state) => {
        this.hasError = true;
        this.changeImageAndTitleAuthor(imageToDisplay, config.translations.error, null);
    }

    addMessageListener = config => {
        const player = this;
        window.addEventListener('message', event => {
            let newConfig;
            try {
                newConfig = JSON.parse(event.data);
            } catch (e) {
                return;
            }
            Object.assign(config, newConfig);
            player.previewConfig = config;
            player.init(config);
            player.changeImageAndTitleAuthor(player.imageToDisplay, player.titleToDisplay, player.authorToDisplay);
        });
    }

    updateMediaSession = () => {
        if ('mediaSession' in navigator) {
            var player = this;
            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: player.titleToDisplay,
                artist: player.authorToDisplay,
                // gif not supported ｡ﾟ･（>﹏<）･ﾟ｡
                artwork: [{
                    sizes: "246x246",
                    src: player.imageToDisplay,
                    type: "image/png"
                }]
            });

            navigator.mediaSession.setActionHandler('play', function () {
                // /!\ Not real play like handlePlayPause function /!\
                player.player.player.player.player.play();
                player.setState({playing: true});
                navigator.mediaSession.playbackState = "playing";
            });
            navigator.mediaSession.setActionHandler('pause', function () {
                // /!\ Not real pause like handlePlayPause function /!\
                player.player.player.player.player.pause();
                player.setState({playing: false});
                navigator.mediaSession.playbackState = "paused";
            });
        }
    }

    ref = player => {
        this.player = player;
    }
}
