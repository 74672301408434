import {Slider} from "@mui/material";
import React from "react";
import PlayerRendererService from '../Service/PlayerRendererService'
import Share from "../IconComponent/Share";

export class PlayerMediumRender extends PlayerRendererService {

    renderNumberListener() {
        let display = "";
        if (this.player.state.numberListener > 0 && this.player.state.displayListener && !this.player.hasError) {
            display =
                <p id="number-listener">{this.player.state.numberListener} {this.player.state.numberListener > 1 ? this.player.state.translations.listeners.toUpperCase() : this.player.state.translations.listener.toUpperCase()}</p>
        }
        return display;
    }

    renderButtonShare() {
        let display = <div id="title-container"><p id="title">{this.player.state.title}</p></div>;
        if (this.player.state.embedEnable || this.player.state.facebook || this.player.state.twitter) {
            display = <div id="title-share" className="buttons">
                {display}
                <Share id="share" alt="share" onClick={() => this.player.handleToggleOverlay("share")}
                       color={this.player.state.buttonColor}/>
            </div>
        }
        return display;
    }

    render() {
        const {
            author,
            volume,
            defaultVolume,
            display,
            translations,
            buttonColor
        } = this.player.state
        let color = {backgroundColor: this.player.state.backgroundColor, color: this.player.state.secondaryColor}

        return <div className='player-wrapper medium-player' style={color}>
            {this.renderQualityOverlay()}
            {this.renderShareOverlay()}
            <div id="player-option-image">
                <div id="player-image">
                    {this.renderPlayOverlay()}
                    <img id="image" className='opacity-transition' src={display} alt="defaultImage"/>
                </div>
            </div>
            <div id="player" className={this.renderWithoutLogoClassStyle()}>
                <div id="player-text">
                    {this.renderButtonShare()}
                    <div id="author-container"><p id="author">{author}</p></div>
                    {this.renderNumberListener()}
                </div>
                <div id="player-buttons" className="buttons">
                    {this.renderPlayPause()}
                    {this.renderSpeaker()}
                    <Slider type='range' min={0} max={1} step={0.0000001} value={volume}
                            style={{color: buttonColor}}
                            defaultValue={defaultVolume}
                            id="volume"
                            onChange={this.player.handleVolumeChange}/>
                    <div id="on-air-block" style={{opacity: this.player.hasError ? 0 : 1}}>
                        <p id="dot" className="on-air">.</p>
                        <p id="on-air">{translations.onair.toUpperCase()}</p>
                    </div>
                    {this.renderButtonQuality()}
                    <div id="logo">
                        <p id="powered-by">by</p>
                        <p id="infomaniak">infomaniak</p>
                    </div>
                </div>
            </div>
            {this.renderReactPlayer()}
        </div>;
    }
}
