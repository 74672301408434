import * as React from "react";
const SvgShare = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m24 10.98-9.972 7.98v-3.974C7.38 14.746 3.379 17.708 0 21 0 13.175 6.968 7.012 14.028 6.943V3L24 10.98Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShare;
