// Use jquery only for Ajax request
import $ from "jquery"
import AppConfig from "../config/config.json"

export var getTranslations = function (locale) {
    const availableLanguages = ['fr', 'en', 'de', 'it', 'es'];
    if (!availableLanguages.includes(locale.toLowerCase())) {
        locale = 'fr';
    }
    let translation = null;
    $.ajax({
        type: "GET",
        async: false,
        url: '/translations/messages.' + locale + '.json',
        dataType: 'json',
        success: function (result) {
            translation = result;
        }
    });
    return translation;
}

export var getConfig = function (urlConfig) {
    let config = null;
    $.ajax({
        type: "GET",
        async: false,
        url: urlConfig,
        success: function (result) {
            config = result;
        }
    });
    return config;
}

export var getConfigAsync = function (urlConfig) {
    return new Promise(resolve => {
        $.ajax({
            type: "GET",
            async: true,
            url: urlConfig,
            success: function (result) {
                resolve(result);
            }
        });
    });
}

export var isAuthorization = function (stream) {
    let url = (stream.format === 'hls' ? AppConfig.urlAccessHLS : AppConfig.urlAccess) + stream.id;
    // If the stream id is -1, it's a test
    if (stream.id === -1) {
        url = (stream.format === 'hls' ? AppConfig.urlAccessHLSMountpoint : AppConfig.urlAccessMountpoint) + stream.name;
    }
    // Default not authorized
    let errcode = -1;
    $.ajax({
        type: "GET",
        async: false,
        url: url,
        success: function (response) {
            errcode = response.errcode
        }
    });
    return errcode >= 0;
}

export var formatConfig = function (config, state) {
    const locale = state.locale ? state.locale : config.lang;
    const autoplay = state.autoplay ? state.autoplay : config.autostart;

    // Change the volume if the default volume change
    let volume = state.volume !== undefined ? state.volume : config.volume / 100;
    if (state.neverPlayed && state.volume !== config.volume) {
        volume = config.volume / 100;
    }
    let title = config.title ? config.title : config.playername;

    let result = {
        playername: config.playername,
        url: config.url ? config.url : state.url,
        title: title,
        author: config.artist,
        displayListener: config.displaylisteners,
        // TODO active for admin3
        numberListener: 0, //config.listeners,
        autoplay: autoplay,
        volume: volume,
        defaultVolume: config.volume / 100,
        logo: config.visiblelogo,
        locale: locale,
        image: getImageFromConfig(config),
        spectrum: config.displayspectrum,
        cover: config.displaycover,
        thumbnail: config.thumbnail.thumbnailurl,
        translations: getTranslations(locale),
        date: config.date,
        embedEnable: config.embedenable,
        facebook: config.facebook.backlink,
        twitter: config.twitter.backlink,
        backgroundColor: "#" + config.colors.backgroundcolor,
        buttonColor: "#" + config.colors.buttoncolor,
        secondaryColor: "#" + config.colors.secondarycolor,
        loadingUrl: switchTranslationFile(config.loadingurl, locale),
        unavailableUrl: switchTranslationFile(config.unavailableurl, locale),
        unauthorizedUrl: switchTranslationFile(config.georestrictionurl, locale)
    }
    if (state.neverPlayed) {
        result.display = config.thumbnail.thumbnailurl ? config.thumbnail.thumbnailurl : AppConfig.storage + 'defaultImage.svg';
    }

    if (config.timeline) {
        result.timeline = getTimelineFromConfig(config, state)
    }

    Object.assign(result, getStreamFromConfig(config, state));
    return result;
}

export var defineSpecificUrl = function (url, config) {
    const splitUrlBySlash = url.split('/');
    const splitUrlByPoint = url.split('.');
    const name = (splitUrlBySlash[splitUrlBySlash.length - 1]).split('?')[0];
    config.title = name;
    config.playername = name;
    config.artist = '';
    config.displaycover = false;
    config.displayspectrum = false;
    config.streams = [{
        "main": true,
        "type": splitUrlByPoint[1],
        "id": -1,
        "uuid": -1,
        "url": url,
        "name": name,
        "format": splitUrlByPoint[splitUrlByPoint.length - 1] === 'm3u8' ? 'hls' : splitUrlByPoint[splitUrlByPoint.length - 1],
        "bitrate": -1
    }];
    if (config.streams[0].format === 'hls') {
        config.streams[0].name = url.split('/')[url.split('/').length - 2];
    }
    return config;
}

function getImageFromConfig(config) {
    let image;
    if (config.displaycover && config.cover) {
        image = config.cover;
    } else if (config.thumbnail.thumbnailurl) {
        image = config.thumbnail.thumbnailurl;
    } else {
        image = AppConfig.storage + 'defaultImage.svg';
    }
    return image;
}

function getTimelineFromConfig(config, state) {
    // Default return if timeline is empty
    if (config.timeline.length === 0) {
        let cover = config.thumbnail.thumbnailurl ? config.thumbnail.thumbnailurl : AppConfig.storage + 'defaultImage.svg'
        return [{date: 1, title: config.playername, author: "", cover: cover}]
    }

    config.timeline.forEach(function (itemConfig, index) {
        if (!config.displaycover || !config.timeline[index].cover) {
            if (config.thumbnail.thumbnailurl) {
                config.timeline[index].cover = config.thumbnail.thumbnailurl;
            } else {
                config.timeline[index].cover = AppConfig.storage + 'defaultImage.svg';
            }
        }

        config.timeline[index].author = config.timeline[index].artist;
        if (config.timeline[index].title === "" || config.timeline[index].title === false) {
            config.timeline[index].title = config.playername;
        }
    });

    config.timeline.concat(state.timeline);
    config.timeline.sort(function (a, b) {
        return b.date < a.date;
    });

    return config.timeline;
}

function getStreamFromConfig(config, state) {
    let result = {streams: [], stream: null};
    // Max 3 streams available
    for (let i = 0; i < 3 && i < config.streams.length; i++) {
        let stream = config.streams[i];
        let newStream = {
            id: stream.uuid,
            url: stream.url,
            name: stream.name
        };

        if (stream.type === 'hls') {
            newStream.format = stream.type;
            result.fragment = stream.fragmentsize;
            result.playlist = stream.playlistsize;
        } else {
            newStream.bitrate = stream.bitrate;
            newStream.format = stream.format;
        }

        if ((stream.main && state.neverPlayed) || state.url === stream.url || (!state.neverPlayed && state.stream.url === stream.url)) {
            result.stream = newStream;
        }
        result.streams.push(newStream);
    }

    return result;
}

function switchTranslationFile(file, locale) {
    const split = file.split('.');
    let newFile = file;
    if (split[split.length - 2].length === 2 && $.inArray(split[split.length - 2], ['fr', 'en', 'de', 'it', 'es']) !== -1) {
        split[split.length - 2] = locale;
        newFile = split.join('.');
    }
    return newFile;
}
