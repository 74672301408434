import * as React from "react";
const SvgPlay = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.291 10.556 4.021.2a1.428 1.428 0 0 0-.715-.2c-.715 0-1.3.563-1.3 1.25H2v21.5h.007c0 .688.584 1.25 1.299 1.25.269 0 .492-.087.735-.212l18.25-10.344A1.84 1.84 0 0 0 23 12a1.85 1.85 0 0 0-.709-1.444Z"
      fill={props.color}
      fillRule="nonzero"
    />
  </svg>
);
export default SvgPlay;
