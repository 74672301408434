import Player from "./Player";
import React from "react";
import {PlayerMediumRender} from '../Page/PlayerMediumRender'

export default class PlayerMedium extends Player {
    render() {
        if (!this.state.stream) {
            return <div/>;
        }
        document.body.style.backgroundColor = this.state.backgroundColor;
        return <PlayerMediumRender player={this}/>;
    }
}