import React, {Component} from "react";
//https://github.com/CookPete/react-player
import ReactPlayer from "react-player";
//https://github.com/nygardk/react-share
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import Play from "../IconComponent/Play";
import Pause from "../IconComponent/Pause";
import Sound from "../IconComponent/Sound";
import SoundOff from "../IconComponent/SoundOff";
import Close from "../IconComponent/Close";
import Selected from "../IconComponent/Selected";
import Settings from "../IconComponent/Settings";

export default class PlayerRendererService extends Component {
    player;

    constructor(props) {
        super();
        this.player = props.player;
    }

    renderButtonQuality() {
        return this.player.state.streams.length > 1 ?
            <Settings id="cog" alt="cog" onClick={() => this.player.handleToggleOverlay("quality")}
                      color={this.player.state.buttonColor}/> : "";
    }

    renderQualityOverlay(addTitle = false) {
        let mainDisplay = "";
        if (this.player.state.streams.length > 1) {
            let displayQuality = [];
            for (let i = 0; i < this.player.state.streams.length; i++) {
                const stream = this.player.state.streams[i];
                let bitrate;
                let displayNone = "d-none";

                // If is default url display checked mark
                if (this.player.state.stream.id === stream.id) {
                    displayNone = "";
                }

                // Add path between quality
                if (i > 0) {
                    displayQuality.push(<span key={"path-" + i} className="path"/>);
                }

                switch (stream.format) {
                    case 'mp3':
                        bitrate = stream.bitrate + " Kbps"
                        break;
                    case 'aac':
                        bitrate = stream.bitrate + " Kbps"
                        break;
                    case 'hls':
                        bitrate = 'auto'
                        break;
                    default:
                }

                displayQuality.push(
                    <div key={i} data-item={i} id={"stream" + stream.id} className="option-text-container"
                         onClick={e => {
                             let streamQuality = this.player.state.streams[e.target.parentNode.dataset.item];
                             if (this.player.state.stream !== streamQuality) {
                                 this.player.load(streamQuality);
                             }
                             this.player.handleToggleOverlay("quality")
                         }}>
                        <div className="option-format">
                            {stream.format.toUpperCase()}
                            <Selected className={"option-check " + displayNone} alt="selected"
                                      color={this.player.state.buttonColor}/>
                        </div>
                        <div className="option-bitrate">{bitrate}</div>
                    </div>
                )
            }
            // Wrap qualities in container
            displayQuality = <div id="option-container">
                {displayQuality}
            </div>

            if (addTitle) {
                // Wrap container in new container with title
                displayQuality =
                    <div id="option-title-container">
                        <div id="title-quality">{this.player.state.translations.quality}</div>
                        {displayQuality}
                    </div>
            }

            // Wrap container in the overlay
            mainDisplay = <div key="quality-overlay" id='quality-overlay'
                // f2 for 0.95 transparence
                               style={{backgroundColor: this.player.state.backgroundColor + "f2"}}>
                <div className='close-container'>
                    <Close className='close-button' alt="close"
                           onClick={() => this.player.handleToggleOverlay("quality")}
                           color={this.player.state.buttonColor}/>
                </div>
                {displayQuality}
            </div>
        }
        return mainDisplay;
    }

    renderShareOverlay() {
        let display = "";
        let shareIframe = "";
        let shareFacebook = "";
        let shareTwitter = "";
        if (this.player.state.embedEnable) {
            shareIframe =
                <>
                    <button className="react-share__ShareButton">
                        <img src={'/img/embed.svg'} alt="embed" onClick={function () {
                            document.getElementById("share-iframe").classList.toggle('fadeIn')
                        }}/>
                    </button>
                    <input id="share-iframe"
                           defaultValue={"<iframe style='height:" + window.innerHeight + "px;width=" + window.innerWidth + "px;' src='" + window.location.href + "'>"}
                           readOnly onFocus={e => e.target.select()}/>
                </>
        }
        if (this.player.state.facebook) {
            shareFacebook = <FacebookShareButton url={this.player.state.facebook}>
                <img src={'/img/facebook.svg'} alt="facebook"/>
            </FacebookShareButton>
        }
        if (this.player.state.twitter) {
            shareTwitter = <TwitterShareButton url={this.player.state.twitter}>
                <img src={'/img/twitter.svg'} alt="twitter"/>
            </TwitterShareButton>
        }

        if (this.player.state.embedEnable || this.player.state.facebook || this.player.state.twitter) {
            // f2 for 0.95 transparence
            display = <div id='share-overlay' style={{backgroundColor: this.player.state.backgroundColor + "f2"}}>
                <div className='close-container'>
                    <Close className='close-button' alt="close"
                           onClick={() => this.player.handleToggleOverlay("share")}
                           color={this.player.state.buttonColor}/>
                </div>
                <div id="share-container">
                    <p>{this.player.state.translations.share}</p>
                    <p id="share-url">{window.location.href}</p>
                    <div id="social-media-buttons">{shareIframe}{shareFacebook}{shareTwitter}</div>
                </div>
            </div>;
        }

        return display;
    }

    renderPlayOverlay() {
        return <div id="circle-play">
            <span id="play-image" className="cursor-pointer front-index" onClick={this.player.handlePlayPause}
                  style={{backgroundColor: this.player.state.backgroundColor}}/>
            <Play color={this.player.state.buttonColor}/>
        </div>;
    }

    renderPlayPause() {
        let display = <Play id="play" onClick={this.player.handlePlayPause} alt="play"
                            color={this.player.state.buttonColor}/>;
        if (this.player.state.playing) {
            display = <Pause id="pause" onClick={this.player.handlePlayPause} alt="pause"
                             color={this.player.state.buttonColor}/>;
        }
        return display;
    }

    renderSpeaker() {
        let display = <Sound id="speaker" onClick={this.player.handleToggleMuted} alt="speaker"
                             color={this.player.state.buttonColor}/>;
        if (this.player.state.muted) {
            display = <SoundOff id="mute" onClick={this.player.handleToggleMuted} alt="mute"
                                color={this.player.state.buttonColor}/>;
        }
        return display;
    }

    renderWithoutLogoClassStyle(initialClasses = "") {
        return !this.player.state.logo ? initialClasses + " without-logo" : initialClasses;
    }

    renderReactPlayer() {
        const {url, playing, light, volume, muted, playbackRate} = this.player.state
        return <ReactPlayer
            id="react-player"
            ref={this.player.ref}
            className='react-player'
            url={url}
            playing={playing}
            light={light}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={this.player.onPlayerReady}
            onProgress={e => this.player.handleProgress(e)}
            onError={e => {
                // real error
                if (e.type && !this.player.state.neverPlayed && this.player.state.playing) {
                    this.player.setPlayerInError(this.player.state.unavailableUrl);
                } else {
                    // not real error
                    this.player.onPlayerReady()
                }
            }}
            config={{
                file: {
                    attributes: {
                        crossOrigin: "anonymous"
                    },
                    hlsOptions: {
                        // Taille du buffer déjà lu à garder en cache (en seconde)
                        liveBackBufferLength: 0,
                        // Taille maximum du buffer pas encore lu à garder en cache (en seconde)
                        maxMaxBufferLength: this.player.state.playlist * this.player.state.fragment,
                        // Nombre de seconde de décalage avec le live (en seconde)
                        liveSyncDuration: this.player.state.playlist * this.player.state.fragment
                    }
                }
            }}
        />
    }
}