import * as React from "react";
const SvgSound = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m5.94 16.783 6.355 5.94c.18.172.43.277.705.277.55 0 1-.425 1-.947V1.947C14 1.426 13.55 1 13 1c-.28 0-.535.11-.715.287l-6.34 5.93H2c-1.105 0-2 .857-2 1.914v5.739c0 1.057.895 1.913 2 1.913h3.94ZM19.625 6.127a1.13 1.13 0 0 0 0 1.655c1.19 1.143 1.93 2.717 1.93 4.457 0 1.741-.745 3.314-1.935 4.458-.48.459-.48 1.2 0 1.655.475.459 1.25.459 1.73 0 1.635-1.565 2.65-3.726 2.65-6.113 0-2.381-1.005-4.539-2.635-6.102l-.01-.01c-.48-.46-1.25-.46-1.73 0Zm-1.035 9.58a4.794 4.794 0 0 0 1.495-3.468c0-1.353-.57-2.578-1.495-3.467a1.259 1.259 0 0 0-1.73 0 1.13 1.13 0 0 0 0 1.655c.48.464.78 1.104.78 1.812s-.3 1.349-.78 1.813a1.13 1.13 0 0 0 0 1.655c.475.454 1.25.459 1.73 0Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSound;
