import * as React from "react";
const SvgSettings = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m22.01 14.4-1.177-.7c-1.276-.8-1.276-2.7 0-3.5l1.178-.7c.981-.6 1.276-1.8.687-2.7l-.982-1.7c-.588-1-1.766-1.3-2.65-.7l-1.177.7c-1.276.8-2.945-.2-2.945-1.7V2c0-1.1-.883-2-1.963-2H11.02c-1.08 0-1.963.9-1.963 2v1.3c0 1.5-1.669 2.5-2.945 1.7l-1.177-.6c-.982-.6-2.16-.2-2.65.7l-.982 1.7c-.49 1-.196 2.2.687 2.8l1.178.7c1.276.7 1.276 2.7 0 3.4l-1.178.7c-.981.6-1.276 1.8-.687 2.7l.982 1.7c.588 1 1.766 1.3 2.65.7l1.177-.6c1.276-.8 2.945.2 2.945 1.7V22c0 1.1.883 2 1.963 2h1.962c1.08 0 1.963-.9 1.963-2v-1.3c0-1.5 1.669-2.5 2.945-1.7l1.177.7c.982.6 2.16.2 2.65-.7l.982-1.7c.49-1.1.196-2.3-.687-2.9ZM12 16c-2.16 0-3.926-1.8-3.926-4S9.841 8 12 8c2.16 0 3.926 1.8 3.926 4S14.159 16 12 16Z"
      fill={props.color}
      fillRule="nonzero"
    />
  </svg>
);
export default SvgSettings;
