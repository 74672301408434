import {Slider} from "@mui/material";
import React from "react";
import PlayerRendererService from '../Service/PlayerRendererService'
import Sound from "../IconComponent/Sound";
import SoundOff from "../IconComponent/SoundOff";
import Share from "../IconComponent/Share";

export class PlayerBigRender extends PlayerRendererService {
    renderButtonShare() {
        let display = "";
        if (this.player.state.embedEnable || this.player.state.facebook || this.player.state.twitter) {
            display =
                <Share id="share" alt="share"
                       onClick={() => this.player.handleToggleOverlay("share")} color={this.player.state.buttonColor}/>
        }
        return display;
    }

    renderPlayerOption() {
        let display = "";
        if ((this.player.state.embedEnable || this.player.state.facebook || this.player.state.twitter || this.player.state.streams.length > 1)
            && !this.player.state.neverPlayed) {
            display = <div id="player-option" className="buttons">
                <div id="player-option-background">
                    <div className="col-left">
                    </div>
                    <div className="col-right">
                        {this.renderButtonQuality()}
                        {this.renderButtonShare()}
                    </div>
                </div>
            </div>;
        }
        return display
    }

    renderSpeaker() {
        let display = <Sound id="speaker" color={this.player.state.buttonColor}
                             onClick={this.player.handleToggleMuted} alt="speaker"
                             onMouseEnter={this.player.handleVolumeHoverEnter}
                             onMouseLeave={this.player.handleVolumeHoverLeaveTimeOut}/>;
        if (this.player.state.muted) {
            display = <SoundOff id="mute" color={this.player.state.buttonColor}
                                onClick={this.player.handleToggleMuted} alt="mute"
                                onMouseEnter={this.player.handleVolumeHoverEnter}
                                onMouseLeave={this.player.handleVolumeHoverLeaveTimeOut}/>;
        }
        return display;
    }

    render() {
        const {
            title,
            author,
            volume,
            defaultVolume,
            display,
            translations,
            buttonColor
        } = this.player.state
        let color = {backgroundColor: this.player.state.backgroundColor, color: this.player.state.secondaryColor}

        return <div className='player-wrapper big-player' style={color}>
            {this.renderQualityOverlay(true)}
            {this.renderShareOverlay()}
            {this.renderPlayerOption()}
            <div id="player-image">
                {this.renderPlayOverlay()}
                <img id="image" src={display} alt="defaultImage"/>
            </div>
            <div id="player">
                <div className="col-left">
                    <div id="title-container"><p id="title">{title}</p>
                    </div>
                    <div id="author-container"><p id="author">{author}</p></div>
                    <div id="on-air-block" style={{opacity: this.player.hasError ? 0 : 1}}>
                        <p id="dot" className="on-air">.</p>
                        <p id="on-air">{translations.onair.toUpperCase()}</p>
                    </div>
                </div>
                <div className={this.renderWithoutLogoClassStyle("col-right")}>
                    <div id="player-buttons" className="buttons">
                        {this.renderPlayPause()}
                        {this.renderSpeaker()}
                        <div id="volume-container" onMouseLeave={this.player.handleVolumeHoverLeaveTimeOut}>
                            <Slider type='range' min={0} max={1} step={0.0000001} value={volume}
                                    style={{color: buttonColor}}
                                    defaultValue={defaultVolume}
                                    id="volume" orientation="vertical"
                                    onChange={this.player.handleVolumeChange}
                                    onMouseEnter={this.player.handleVolumeHoverEnter}/>
                        </div>
                    </div>
                    <div id="logo">
                        <p id="powered-by">powered by</p>
                        <p id="infomaniak">infomaniak</p>
                    </div>
                </div>
            </div>
            {this.renderReactPlayer()}
        </div>;
    }
}
