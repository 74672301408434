import * as React from "react";
const SvgClose = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.052.014a1.679 1.679 0 0 0-.987.488l-9.067 9.07L2.935.502A1.687 1.687 0 0 0 1.718 0C1.252 0 .83.173.502.502A1.68 1.68 0 0 0 0 1.712l.014.227c.048.372.215.708.488.981l9.08 9.087-9.08 9.072A1.68 1.68 0 0 0 0 22.29c0 .462.174.881.502 1.209.174.174.376.307.602.39a1.745 1.745 0 0 0 1.228 0l.165-.071c.16-.08.307-.188.438-.32l9.063-9.071 9.067 9.072c.175.174.377.307.603.39a1.745 1.745 0 0 0 1.228 0c.226-.083.428-.216.602-.39a1.68 1.68 0 0 0 .502-1.21l-.014-.227a1.677 1.677 0 0 0-.488-.981l-9.082-9.073 9.082-9.087A1.68 1.68 0 0 0 24 1.711a1.68 1.68 0 0 0-.502-1.209A1.687 1.687 0 0 0 22.282 0l-.23.014Z"
      fill={props.color}
      fillRule="nonzero"
    />
  </svg>
);
export default SvgClose;
