import * as React from "react";
const SvgPause = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.369 0v24H5V0h4.369ZM20 0v24h-4.369V0H20Z"
      fill={props.color}
      fillRule="nonzero"
    />
  </svg>
);
export default SvgPause;
