import React, {Component} from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useParams
} from 'react-router-dom';
import './Service/PlayerRendererService'
import PlayerBig from './Model/PlayerBig'
import PlayerMedium from './Model/PlayerMedium';
import PlayerSmall from './Model/PlayerSmall';
import AppConfig from './config/config.json';
import Error404 from "./Page/Error404";
import $ from "jquery";

class App extends Component {
    render() {
        let urlParams = new URLSearchParams(window.location.search);
        let locale = urlParams.get("locale");
        let autoplay = urlParams.get("autoplay");
        let preview = urlParams.get("preview");
        let url = urlParams.get("url");
        let splitUrl = window.location.href.split('/');
        let uuid = splitUrl.pop();

        if (!window.location.href.includes('/2/prod/') && !window.location.href.includes('/404')) {
            var requestSuccess = false;
            $.ajax({
                type: "GET",
                async: false,
                url: AppConfig.urlConfigV2 + uuid + '/exist',
                success: function (result) {
                    requestSuccess = result.data;
                    if (result.data == true) {
                        // Remove station name
                        splitUrl.pop();
                        let redirectUrlToV2 = (splitUrl.join('/') + '/' + uuid).replace('/prod/', '/2/prod/');
                        window.location.replace(redirectUrlToV2)
                    }
                }
            });
            if (requestSuccess) {
                return;
            }
        }

        const PlayerBigRoute = function (params) {
            let {configId, radioName} = useParams();
            if (AppConfig.env !== "dev" && params.isTest) {
                return "";
            }
            return <PlayerBig isTest={params.isTest} configId={configId} radioName={radioName} locale={locale}
                              autoplay={autoplay} preview={preview} url={url}/>;
        }
        const PlayerMediumRoute = function (params) {
            let {configId, radioName} = useParams();
            if (AppConfig.env !== "dev" && params.isTest) {
                return "";
            }
            return <PlayerMedium isTest={params.isTest} configId={configId} radioName={radioName} locale={locale}
                                 autoplay={autoplay} preview={preview} url={url}/>;
        }

        const PlayerSmallRoute = function (params) {
            let {configId, radioName} = useParams();
            if (AppConfig.env !== "dev" && params.isTest) {
                return "";
            }
            return <PlayerSmall isTest={params.isTest} configId={configId} radioName={radioName} locale={locale}
                                autoplay={autoplay} preview={preview} url={url}/>;
        }

        return <Router>
            <Routes>
                <Route path="/playerbig" element={<PlayerBigRoute isTest={true}/>}></Route>
                <Route path="/playermedium" element={<PlayerMediumRoute isTest={true}/>}></Route>
                <Route path="/playersmall" element={<PlayerSmallRoute isTest={true}/>}></Route>
                <Route path="/prod/big/:radioName/:configId" element={<PlayerBigRoute isTest={false}/>}></Route>
                <Route path="/prod/medium/:radioName/:configId" element={<PlayerMediumRoute isTest={false}/>}></Route>
                <Route path="/prod/small/:radioName/:configId" element={<PlayerSmallRoute isTest={false}/>}></Route>
                <Route path="/404" element={<Error404/>}></Route>
            </Routes>
        </Router>;
    }
}

export default App
