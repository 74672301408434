import React, {Component} from "react";

export default class Error404 extends Component {
    componentDidMount() {
        document.title = "Player introuvable"
    }

    render() {
        return <div id="error-container">
            <h1>Player introuvable</h1>
            <p>Le player que vous recherchez a été déplacé, effacé, renommé, ou n'a peut être jamais existé.</p>
        </div>;
    }
}
