import * as React from "react";
const SvgSoundOff = (props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.143 2.5c.471 0 .857.368.857.818v17.364c0 .45-.386.818-.857.818a.868.868 0 0 1-.604-.24L5.09 16.13H1.714C.767 16.13 0 15.391 0 14.478V9.522C0 8.609.767 7.87 1.714 7.87h3.381l5.435-5.122a.87.87 0 0 1 .613-.248ZM22.8 7.759c.329 0 .628.124.857.356a1.18 1.18 0 0 1 .25 1.291 1.055 1.055 0 0 1-.26.377l-2.752 2.729 2.751 2.722c.073.072.136.151.187.238l.07.134c.065.147.097.304.097.465a1.188 1.188 0 0 1-1.2 1.188c-.16 0-.317-.031-.465-.092a1.068 1.068 0 0 1-.381-.258l-2.755-2.727-2.804 2.777c-.055.05-.115.095-.18.133l-.15.075a1.214 1.214 0 0 1-.465.092 1.188 1.188 0 0 1-1.2-1.188c0-.16.032-.318.097-.465.061-.14.148-.265.257-.372l2.748-2.722-2.8-2.779a1.1 1.1 0 0 1-.133-.177l-.076-.15a1.18 1.18 0 0 1 .25-1.29 1.214 1.214 0 0 1 1.322-.264c.157.064.296.16.381.257l2.754 2.725 2.805-2.776c.055-.05.115-.094.18-.132l.15-.075c.148-.061.305-.092.465-.092Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSoundOff;
