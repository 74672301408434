import {Slider} from "@mui/material";
import React from "react";
import PlayerRendererService from '../Service/PlayerRendererService'
import Settings from "../IconComponent/Settings";

export class PlayerSmallRender extends PlayerRendererService {

    renderButtonQuality() {
        let display = <div id="volume-container">
            <Slider type='range' min={0} max={1} step={0.0000001} value={this.player.state.volume}
                    defaultValue={this.player.state.defaultVolume}
                    id="volume"
                    style={{color: this.player.state.buttonColor}}
                    onChange={this.player.handleVolumeChange}/>
        </div>
        if (this.player.state.streams.length > 1) {
            display = <Settings id="cog" alt="cog" onClick={() => this.player.handleToggleOverlay("quality")}
                                color={this.player.state.buttonColor}/>
        }
        return display;
    }

    render() {
        const {title, author, display} = this.player.state
        let color = {backgroundColor: this.player.state.backgroundColor, color: this.player.state.secondaryColor}

        return <div className='player-wrapper small-player' style={color}>
            {this.renderQualityOverlay()}
            <div id="player-image">
                {this.renderPlayOverlay()}
                <img id="image" className='opacity-transition' src={display} alt="defaultImage"/>
            </div>
            <div id="player">
                <div id="title-container"><p id="title">{title}</p></div>
                <div id="author-container"><p id="author">{author}</p></div>
                <div id="controls" className={this.renderWithoutLogoClassStyle()}>
                    <div id="player-buttons" className="buttons">
                        {this.renderPlayPause()}
                        {this.renderSpeaker()}
                        {this.renderButtonQuality()}
                    </div>
                    <div id="logo">
                        <p id="powered-by">powered by</p>
                        <p id="infomaniak">infomaniak</p>
                    </div>
                </div>

            </div>
            {this.renderReactPlayer()}
        </div>;
    }
}
